import { tsParticles } from 'tsparticles'
import particlesHomeConfig from './particlesHome.json'
import particlesSkillsConfig from './particlesSkills.json'
import './contact'

/********************
 * ELEMENT CACHING
 ********************/

const $mainNav = document.getElementById('main-nav')
const $navLogo = $mainNav.querySelector('.logo-link')

const $sectionHome = document.getElementById('section-home')
const $homeLogo    = $sectionHome.querySelector('.logo')


/********************
 * STATE
 ********************/

let currentPageSection  = ''
let preventScrollSectionChange = false
let scrollEventTimer


/********************
 * FUNCTIONS
 ********************/

// This unfortunately exists because there is no current way to detect when css scroll-behavior smooth scroll effect triggered via window.scrollTo is actually finished
// And we don't want to trigger section scroll-in history events when we're navigating between sections via the top nav bar while the viewport is smooth scrolling
// Which is essentially just a complicated way of saying "skip some stuff we would normally do when scrolling, under certain circumstances"
function onScroll() {
	clearTimeout(scrollEventTimer)
		
	scrollEventTimer = setTimeout(() => {
		preventScrollSectionChange = false
		window.removeEventListener('scroll', onScroll)
	}, 66)
}

function onHistoryChange(skipScrollTo) {
	// What page are we going to
	const page = history.state && history.state.page ? history.state.page : location.pathname.substring(1)
	let newPageSection = page !== '' ? page : 'home'
	if (newPageSection === currentPageSection) return
	currentPageSection = newPageSection

	// Little one liner to toggle the active class on the right nav link
	$mainNav.querySelectorAll('.page-links a').forEach(el => el.classList.toggle('active', el.getAttribute('href') === `/${currentPageSection}`))

	if (!skipScrollTo) {
		const scrollTo = document.getElementById(`section-${currentPageSection}`).offsetTop

		// Skip scrolling if we're...already there
		if (window.scrollY !== scrollTo) {
			preventScrollSectionChange = true
			window.addEventListener('scroll', onScroll, {passive: true})
			window.scrollTo(0, scrollTo)
		}
	}
}

function gotoPage(page, skipScrollTo) {
	history.replaceState({page: page}, '', page === 'home' ? '/' : page)
	onHistoryChange(skipScrollTo)
}


/********************
 * LISTENERS/OBSERVERS
 ********************/

// All link requests that aren't external get hijacked so we can scroll the user to the appropriate section
document.querySelectorAll('a[href^="/"]:not([target="_blank"])').forEach(el => {
	el.addEventListener('click', e => {
		e.preventDefault()
		gotoPage(el.getAttribute('href').substring(1))
	})
})

// Here we determine when a page section has majority viewport visibility, and toggle history state accordingly
// Caveat: We gotta listen to window resize to be sure our observer threshold is always correct, which is annoying, but we debounce so...kinda ok
let pageSectionObservers = []
function attachPageSectionObservers() {
	if (pageSectionObservers.length) {
		pageSectionObservers.forEach(observer => observer.disconnect())
		pageSectionObservers = []
	}

	document.querySelectorAll('.page-section').forEach(el => {
		let threshold = 0.5
		if (el.offsetHeight > window.innerHeight) {
			threshold = (window.innerHeight / el.offsetHeight) * threshold
		}

		const pageSectionObserver = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					if (!preventScrollSectionChange) {
						gotoPage(entry.target.id.substring(8), true)
					}
				}
			})
		}, {threshold: threshold})
		pageSectionObserver.observe(el)
		pageSectionObservers.push(pageSectionObserver)
	})
}
attachPageSectionObservers()
let resizeEventTimer
window.addEventListener('resize', () => {
	clearTimeout(resizeEventTimer)

	resizeEventTimer = setTimeout(() => {
		attachPageSectionObservers()
	}, 300)
})

// Adjust the page nav when we scroll below the home logo
const homeLogoObserver = new IntersectionObserver(entries => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			$mainNav.classList.remove('active')
			$navLogo.setAttribute('aria-hidden', 'true')
			$navLogo.setAttribute('tabindex', '-1')
		} else {
			$mainNav.classList.add('active')
			$navLogo.setAttribute('aria-hidden', 'false')
			$navLogo.setAttribute('tabindex', '0')
		}
	})
})
homeLogoObserver.observe($homeLogo)

// Particle containers that we pause/play if visible (to control tab CPU usage if the viewer is on a toaster I guess)
const particleStore = {
	'section-home': {
		container: null,
	},
	'section-skills': {
		container: null,
	},
}
const particleObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
			particleStore[entry.target.id].container.play()
    } else {
			particleStore[entry.target.id].container.pause()
		}
  })
})
for (const [elementId] of Object.entries(particleStore)) {
	particleObserver.observe(document.getElementById(elementId))
}

// Elements which we toggle an active class on once they scroll into view
const elementsToShow = document.querySelectorAll('.animate-in')
const toShowObserver = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
			entry.target.classList.add('active')
			observer.unobserve(entry.target)
    }
  })
})
elementsToShow.forEach(el => {
  toShowObserver.observe(el)
})

/********************
 * INIT
 ********************/

// Stops (some) browsers from scrolling back to where the user was pre-reload
// We do this because we guide the user's scroll position on load ourselves a smidge
if (history.scrollRestoration) {
  history.scrollRestoration = 'manual'
}

$sectionHome.classList.add('active')

tsParticles.load('section-home', particlesHomeConfig)
	.then(container => {
		particleStore['section-home'].container = container
	})

tsParticles.load('section-skills', particlesSkillsConfig)
	.then(container => {
		particleStore['section-skills'].container = container
	})

onHistoryChange()

// Added after load because we don't want to animate the initial scroll-to-section, which makes the site appear "slow" to load
document.documentElement.classList.add('smooth-scroll')
