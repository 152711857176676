const $form				  = document.querySelector('#contact-form')
const $inputEmail   = document.querySelector('#contact-email')
const $inputMessage = document.querySelector('#contact-message')
const $submitBtn	  = $form.querySelector('button[type="submit"]')

const inputEmailMin   = parseInt($inputEmail.getAttribute('minlength'))
const inputMessageMin = parseInt($inputMessage.getAttribute('minlength'))
const inputMessageMax = parseInt($inputMessage.getAttribute('maxlength'))

let loading = false


function toggleAlert ($alert, code) {
	if ($alert.classList.contains('active')) {
		$form.classList.remove('has-alert')
		$alert.classList.remove('active')
		$alert.setAttribute('aria-hidden', 'true')
		$alert.setAttribute('tabindex', '-1')
	} else {
		$form.classList.add('has-alert')
		$alert.classList.add('active')
		$alert.setAttribute('aria-hidden', 'false')
		$alert.setAttribute('tabindex', '0')
		if (code) {
			$alert.querySelector('.error-code').innerHTML = `(Err: ${code})`
		}
	}
}


$inputEmail.addEventListener('blur', e => {
	const $fieldset = $inputEmail.closest('fieldset')
	const $errorTip = $fieldset.querySelector('.error-tip')

	if (e.target.value.length < inputEmailMin) {
		$fieldset.classList.remove('valid')
		$fieldset.classList.add('error')
		$errorTip.innerHTML = 'Please enter a valid email address'
	} else {
		$fieldset.classList.add('valid')
		$fieldset.classList.remove('error')
	}
})

$inputMessage.addEventListener('blur', e => {
	const $fieldset = $inputMessage.closest('fieldset')
	const $errorTip = $fieldset.querySelector('.error-tip')

	if (e.target.value.length < inputMessageMin || e.target.value.length > inputMessageMax) {
		$fieldset.classList.remove('valid')
		$fieldset.classList.add('error')

		if (e.target.value.length < inputMessageMin) {
			$errorTip.innerHTML = `Your message is a tad too short! Please enter at least ${inputMessageMin} characters.`
		} else if (e.target.value.length > inputMessageMax) {
			$errorTip.innerHTML = `Your message is a bit too long! Please keep it under ${inputMessageMax} characters.`
		}
	} else {
		$fieldset.classList.add('valid')
		$fieldset.classList.remove('error')
	}
})

$form.querySelectorAll('.alert .close').forEach($el => {
	$el.addEventListener('click', () => {
		toggleAlert($el.closest('.alert'))
	})
})

$form.addEventListener('submit', e => {
	e.preventDefault()

	if (loading) return
	loading = true

	$submitBtn.classList.add('loading')
	$submitBtn.setAttribute('disabled', 'disabled')

	fetch($form.getAttribute('action'), {
		method: $form.getAttribute('method'),
		headers: new Headers({
      'X-FROM-AJAX': 'true',
    }),
		body: new FormData($form)
	})
	.then(response => {
		if (!response.ok) {
			throw Error(response.statusText)
		}
		return response.json()
	})
	.then(response => {
		console.log('1', response)
		if (response.error) {
			toggleAlert($form.querySelector('.send-error'), response.code)
		} else if (response.success) {
			$form.reset()
			toggleAlert($form.querySelector('.send-success'))
		}
	})
	.catch(error => {
		console.log('2', error)
		toggleAlert($form.querySelector('.send-error'), 99)
	})
	.then(() => {
		loading = false
		$submitBtn.classList.remove('loading')
		$submitBtn.removeAttribute('disabled')
		$form.querySelectorAll('fieldset').forEach($el => {
			$el.closest('fieldset').classList.remove('valid', 'error')
		})
	})
})